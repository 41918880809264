import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import googlePlay from '../images/googlePlay.svg';
import appStore from '../images/appStore.png';

import * as styles from './About.module.scss';

interface AboutProps {
  aboutImage: {
    asset: {
      url: string;
    };
  };
  aboutTitle: string;
  aboutDescription: string;
}

const About = (): React.ReactElement => {
  const data = useStaticQuery(graphql`
    {
      sanityHomepage {
        aboutImage {
          asset {
            url
          }
        }
        aboutTitle
        aboutDescription
      }
    }
  `);

  const { aboutImage, aboutTitle, aboutDescription }: AboutProps = data.sanityHomepage;

  return (
    <section id="plataformas" className={styles.aboutSection}>
      <div className={styles.container}>
        <div className={styles.leftContainer + ' imageContainer'}>
          <img src={aboutImage.asset.url}></img>
        </div>
        <div className={styles.rightContainer}>
          <h2 className={styles.title + ' title'}>{aboutTitle}</h2>
          <p className={styles.description + ' paragraph'}>{aboutDescription}</p>
          {/* <div className={styles.linksSection}>
            <span>Descarrega a App</span>
            <div className={styles.links}>
              <div className={styles.appStoreAndGooglePlayLinks}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://play.google.com/store/apps/details?id=pt.vivavoz"
                  className={styles.appLink}
                >
                  <img src={googlePlay} alt="" />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://apps.apple.com/pt/app/pt.vivavoz/id1579394431"
                  className={styles.appLink}
                >
                  <img src={appStore} alt="" />
                </a>
              </div>
              <span>ou</span>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://app.vivavoz.pt/"
                className="button darkButton"
              >
                Abrir na Web
              </a>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default About;
