import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

import Hero from '../components/Hero';
import About from '../components/About';
import Advantages from '../components/Advantages';
import Cta from '../components/Cta';
import Form from '../components/Form';
import { DEFAULT_LANG } from '../constants';

import '../fragments.ts';
import { SEOQueryData } from '../fragments';

import '../styles/main.scss';

interface QueryData {
  sanityWebsite: {
    seo: SEOQueryData;
  };
  sanityHomepage: {
    seo?: SEOQueryData;
  };
}

const IndexPage = (): React.ReactElement => {
  const data: QueryData = useStaticQuery(graphql`
    {
      sanityWebsite {
        seo {
          ...SEOWebsiteFragment
        }
      }
      sanityHomepage {
        seo {
          ...SEOFragment
        }
      }
    }
  `);

  const { title, image, url, description } = data.sanityWebsite.seo;
  const { seo } = data.sanityHomepage;

  return (
    <Layout>
      <SEO
        title={title}
        description={seo && seo.description ? seo.description : description}
        url={seo && seo.url ? seo.url : url}
        imageUrl={seo && seo.image ? seo.image.asset.url : image.asset.url}
        lang={DEFAULT_LANG}
      />

      <Hero></Hero>
      <About></About>
      <Advantages></Advantages>
      <Cta></Cta>
      <Form></Form>
    </Layout>
  );
};

export default IndexPage;
