import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import getYoutubeID from 'get-youtube-id';
import * as styles from './Cta.module.scss';

interface CtaProps {
  ctaTitle: string;
  ctaDescription: string;
  ctaImage: {
    asset: {
      url: string;
    };
  };
  ctaVideoUrl?: string;
  ctaButton: string;
  ctaButtonUrl: string;
  ctaButtonDescription: string;
}

const Cta = (): React.ReactElement => {
  const data = useStaticQuery(graphql`
    {
      sanityHomepage {
        ctaTitle
        ctaDescription
        ctaImage {
          asset {
            url
          }
        }
        ctaVideoUrl
        ctaButton
        ctaButtonUrl
        ctaButtonDescription
      }
    }
  `);

  const {
    ctaTitle,
    ctaDescription,
    ctaImage,
    ctaVideoUrl,
    ctaButton,
    ctaButtonUrl,
    ctaButtonDescription,
  }: CtaProps = data.sanityHomepage;

  let embedUrl;
  if (ctaVideoUrl) {
    const id = getYoutubeID(ctaVideoUrl);
    if (!id) {
      throw new Error('Could not extract youtube id from url: ' + ctaVideoUrl);
    }
    embedUrl = `https://www.youtube.com/embed/${id}`;
  }

  return (
    <section id="cta" className={styles.ctaSection}>
      <div className={styles.container}>
        <div
          className={
            styles.leftContainer +
            ' ' +
            (embedUrl ? styles.videoContainer : styles.imageContainer) +
            ' imageContainer'
          }
        >
          {embedUrl ? (
            <iframe key="video" className={styles.iframe} src={embedUrl}></iframe>
          ) : (
            <img className={styles.image} src={ctaImage.asset.url}></img>
          )}
        </div>
        <div className={styles.rightContainer}>
          <h2 className={styles.title + ' title'}>{ctaTitle}</h2>
          <p className={styles.description + ' paragraph'}>{ctaDescription}</p>

          {/* <div className={styles.buttonContainer}>
            <a
              target="_blank"
              rel="noreferrer"
              className="button darkButton"
              onClick={() => window.open(ctaButtonUrl)}
            >
              {ctaButton}
            </a>
            <span>{ctaButtonDescription}</span>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Cta;
