// extracted by mini-css-extract-plugin
export const aboutSection = "About-module--aboutSection--nF-y4";
export const container = "About-module--container--1WnQb";
export const leftContainer = "About-module--leftContainer--2aeNP";
export const rightContainer = "About-module--rightContainer--2jOsG";
export const description = "About-module--description--22rCi";
export const linksSection = "About-module--linksSection--LY_VP";
export const links = "About-module--links--2bHG4";
export const appStoreAndGooglePlayLinks = "About-module--appStoreAndGooglePlayLinks--2dbZ-";
export const appLink = "About-module--appLink--1niui";
export const title = "About-module--title--3W2d9";