import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';

import { useFormField, useForm } from '../utils/forms';
import { RawPortableText } from './../../src/types';
import { GenericLinkSerializer } from '../serializers';
import InputField from './InputField';

import '../styles/main.scss';
import * as styles from './Form.module.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function encodeFormData(data: any) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

function wait(ms: number) {
  return new Promise(function (resolve, reject) {
    setTimeout(resolve, ms);
  });
}

interface FormProps {
  formTitle: string;
  _rawFormDescription: RawPortableText;
}

const Form = (): React.ReactElement => {
  const data = useStaticQuery(graphql`
    {
      sanityHomepage {
        formTitle
        _rawFormDescription(resolveReferences: { maxDepth: 4 })
      }
    }
  `);

  const { formTitle, _rawFormDescription }: FormProps = data.sanityHomepage;

  const fieldsByName = {
    name: useFormField<string>('', ['required']),
    email: useFormField<string>('', ['required', 'email']),
    message: useFormField<string>('', ['required']),
  } as const;

  async function onSubmit() {
    // await wait(2000); // Case loading
    // throw new Error('Got response with status code 400'); // Case unknown error
    // throw new Error('Failed to fetch'); // Case network error
    // return true; // Case success
    const resp = await fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encodeFormData({
        'form-name': 'contact',
        ...{
          name: fieldsByName.name.value,
          email: fieldsByName.email.value,
          message: fieldsByName.message.value,
        },
      }),
    });
    if (resp.status !== 200) {
      throw new Error('Got response with status code ' + resp.status);
    }
    return true;
  }

  const { getFieldProps, renderSubmitButton, renderFormMessage } = useForm({
    fieldsByName,
    onSubmit,
    translateFunction: key => {
      return {
        'form.required_field_error': 'Este campo é obrigatório',
        'form.invalid_email_error': 'Email inválido (e.g. email@example.com)',
        'form.network_error': 'A rede falhou ao enviar o seu pedido, por favor tente novamente.',
        'form.unknown_error': 'Ocorreu um erro desconhecido, por favor tente novamente.',
        'form.success_message': 'Obrigado pela sua mensagem, entraremos em contacto em breve.',
      }[key];
    },
  });

  return (
    <section id="formulario" className={styles.formSection}>
      <div className={styles.container}>
        <div className={styles.leftContainer}>
          <h2 className={styles.title + ' title'}>{formTitle}</h2>
          <BlockContent
            className={styles.description + ' paragraph'}
            renderContainerOnSingleChild
            blocks={_rawFormDescription}
            serializers={{
              marks: {
                genericLink: GenericLinkSerializer,
              },
            }}
          />
        </div>
        <div className={styles.rightContainer}>
          <form name="contact" data-netlify="true" data-netlify-honeypot="hidden-field">
            <input
              className={styles.hidden}
              name="hidden-field"
              placeholder="language"
              autoComplete="language"
              type="text"
            />

            <div className={styles.inputContainer}>
              <label className={styles.label} htmlFor="name-input">
                Nome
              </label>
              <InputField
                className={styles.input}
                containerClass={styles.inputContent}
                type="text"
                id="name-input"
                name="name"
                helperTextClass={styles.helperText}
                {...getFieldProps(fieldsByName.name)}
              />
            </div>

            <div className={styles.inputContainer}>
              <label className={styles.label} htmlFor="email-input">
                Email
              </label>
              <InputField
                className={styles.input}
                containerClass={styles.inputContent}
                type="email"
                id="email-input"
                name="email"
                helperTextClass={styles.helperText}
                {...getFieldProps(fieldsByName.email)}
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.label} htmlFor="message-input">
                Mensagem
              </label>
              <InputField
                className={styles.input + ' ' + styles.messageInput}
                containerClass={styles.inputContent}
                textarea
                id="message-input"
                name="message"
                helperTextClass={styles.helperText}
                {...getFieldProps(fieldsByName.message)}
              />
            </div>
            {renderSubmitButton({
              labels: {
                disabled: 'Enviar',
                ready: 'Enviar',
                submitting: 'A enviar',
                submitted: 'Enviado',
              },
              btnClasses: {
                common: 'submitButton button',
                disabled: 'formDisabled',
                ready: 'formReady',
                submitting: 'formSubmitting',
                submitted: 'formSubmitted',
              },
              iconClasses: {
                common: 'fas',
                disabled: '',
                ready: '',
                submitting: 'fa-spinner fa-spin',
                submitted: 'fa-check',
              },
            })}
            {renderFormMessage({
              styles: {
                formMessage: 'formMessage',
                formMessageSuccess: 'formMessageSuccess',
                formMessageError: 'formMessageError',
              },
            })}
            <span className="fakeFormMessage"></span>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Form;
