import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import logoHero from '../images/logo-hero.png';

import * as styles from './Hero.module.scss';

interface HeroProps {
  heroImage: {
    asset: {
      url: string;
    };
  };
  heroTitle: string;
  heroDescription: string;
  heroButton: string;
  heroBelowButtonText: string;
  heroButtonUrl: string;
}

const Hero = (): React.ReactElement => {
  const data = useStaticQuery(graphql`
    {
      sanityHomepage {
        heroImage {
          asset {
            url
          }
        }
        heroTitle
        heroDescription
        heroButton
        heroBelowButtonText
        heroButtonUrl
      }
    }
  `);

  const {
    heroImage,
    heroTitle,
    heroDescription,
    heroButton,
    heroBelowButtonText,
    heroButtonUrl,
  }: HeroProps = data.sanityHomepage;

  return (
    <section id="hero" className={styles.heroSection}>
      <div className={styles.sideImageWrapper + ' ' + styles.leftImageWrapper + ' imageContainer'}>
        <img src={logoHero}></img>
      </div>
      <div className={styles.container}>
        <h2 className={styles.title}>{heroTitle}</h2>
        <p className={styles.description + ' paragraph'}>{heroDescription}</p>
        <div className={styles.buttonContainer}>
          <button
            className="button darkButton"
            style={{ borderRadius: 2 }}
            //  onClick={() => window.open(heroButtonUrl)}
          >
            {/* {heroButton} */}
            Estreia a 1 de dezembro
          </button>
          {/* <span>{heroBelowButtonText}</span> */}
        </div>
      </div>
      <div className={styles.sideImageWrapper + ' ' + styles.rightImageWrapper + ' imageContainer'}>
        <img src={heroImage.asset.url}></img>
      </div>
    </section>
  );
};

export default Hero;
