import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';
import { RawPortableText } from './../../src/types';
import { GenericLinkSerializer } from '../serializers';

import * as styles from './Advantages.module.scss';

interface AdvantagesProps {
  advantagesImage: Array<{
    asset: {
      url: string;
    };
  }>;
  advantagesTitle: string;
  _rawAdvantagesDescription: RawPortableText;
}

const Advantages = (): React.ReactElement => {
  const data = useStaticQuery(graphql`
    {
      sanityHomepage {
        advantagesImage {
          asset {
            url
          }
        }
        advantagesTitle
        _rawAdvantagesDescription(resolveReferences: { maxDepth: 4 })
      }
    }
  `);

  const {
    advantagesImage,
    advantagesTitle,
    _rawAdvantagesDescription,
  }: AdvantagesProps = data.sanityHomepage;

  return (
    <section id="vantagens" className={styles.advantagesSection}>
      <div className={styles.container}>
        <div className={styles.leftContainer}>
          <h2 className={styles.title + ' title'}>{advantagesTitle}</h2>
          <BlockContent
            className={styles.description + ' paragraph'}
            renderContainerOnSingleChild
            blocks={_rawAdvantagesDescription}
            serializers={{
              marks: {
                genericLink: GenericLinkSerializer,
              },
            }}
          />
        </div>
        <div className={styles.rightContainer + ' imageContainer'}>
          {advantagesImage.map((image, index) => {
            return <img key={index} className={styles.image} src={image.asset.url}></img>;
          })}
        </div>
      </div>
    </section>
  );
};

export default Advantages;
