// extracted by mini-css-extract-plugin
export const formSection = "Form-module--formSection--hbbuQ";
export const container = "Form-module--container--25Geh";
export const leftContainer = "Form-module--leftContainer--2F_6d";
export const description = "Form-module--description--3HqHK";
export const rightContainer = "Form-module--rightContainer--1OnjZ";
export const hidden = "Form-module--hidden--3sh4W";
export const inputContainer = "Form-module--inputContainer--1s3ej";
export const inputContent = "Form-module--inputContent--1GYxM";
export const input = "Form-module--input--11_rQ";
export const messageInput = "Form-module--messageInput--26kYV";
export const label = "Form-module--label--Q2Wus";
export const helperText = "Form-module--helperText--O7m4L";